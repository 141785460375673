body {
  color: white!important;
  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0;
}

.ant-form-item-label {
  white-space: nowrap!important;
  overflow: visible!important;
}

.ant-table-title {
  text-align: start;
}

.ant-table-column-title {
  z-index: 0!important;
}