
.header {
  position: fixed;
  top: 0;
  z-index: 3;
  width: 100vw;
  background: #001529!important;
  box-shadow: rgba(255, 255, 255, 0.25) 0px 3px 6px -1px, rgba(255, 255, 255, 0.3) 0px 1.5px 3.5px -1.5px;
  height: 64px;
  opacity: 0.95;
}

.content {
  margin-top: 25px;
  margin-bottom: 150px;
  padding: 0 2%;
  padding-top: 60px;
}